/**
 * Base
 */

// Box sizing
* {
    box-sizing: border-box;

    &:before,
    &:after {box-sizing: border-box;}
}


// Body
body {
    background-color: @color-white;
    font-family: @base-font-family;
    font-weight: @base-font-weight;
    font-size: @base-font-size;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: @color-text;
    line-height: 1.5;
    overflow-x: hidden;
    position: relative;
    width: 100%;

    @media (min-width:@screen-lg) {font-size: 18px;}
}


// Links
a {
    color: @color-link;
    text-decoration: underline;

    &:hover,
    &:focus {color: darken(@color-link, 10%);}
}


// Image handling
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;

        &.alignleft {
            float: left;
            margin-left: 0;
            margin-bottom: 30px;
            margin-right: 30px;
        }

        &.alignright {
            float: right;
            margin-right: 0;
            margin-bottom: 30px;
            margin-left: 30px;
        }

        &.aligncenter {
            margin: 0 auto;
            margin-bottom: 30px;
        }
    }

	&.contain-width img { width: auto; }

    // Responsive video embeds
    &.video-embed-wrapper {
        position: relative;
        padding-bottom: 56.25%; /* 16:9 */
        height: 0;
        overflow: hidden;
        cursor: pointer;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}

img{
    max-width: 100%;
    height: auto;
}