.image-slider {
    margin-bottom: 5px;
    overflow: hidden;
    
    .owl-item {
        float: left;
        img { display: block; }
    }
    .owl-nav { display: none; }

    &__item {
        position: relative;
        img {
            width: 100%;
            height: auto;
        }
    }

    &__item-desc {
        position: relative;
        display: block;
        text-align: left;
        background: fade(#000000, 60%);
        color: @color-white;
        padding: 20px;
        font-size: .9em;

        @media (min-width: @screen-ms) {
            position: absolute;
            display: inline-block;
            bottom: 0;
            left: 0;
            max-width: 100%;
        }

        @media (min-width: @screen-sm) {
            max-width: 50%;
        }
    }

    &__thumbnails {
        .clearfix();
        margin-right: -5px;
    }

    &__thumbnail-item {
        .clearfix();

        width: ~"calc(100% / 4)";
        display: inline-block;
        float: left;

        img { 
            opacity: .5;
            display: block;
            padding: 0 5px 5px 0;
            transition: opacity .3s;
            cursor: pointer;
        }

        &.active { img { opacity: 1; } }

        @media (min-width: @screen-sm) {
            width: ~"calc(100% / 5)";
        }
    }
}