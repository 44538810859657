.navigation-categories{
    width: 100%;
    height: 100px;
    background-color: @color-primary;
    position: fixed;
    bottom: 0;
    left:50%;
    transform:translateX(-50%;);

    @media (min-width: @screen-ms) {
      width: auto;
      min-width: 360px;
    }

    &__list{
        list-style-type: none;
        padding: 0 55px;
        margin: 0 auto;
        //display: table;
        @media (min-width: @screen-ms) {
            padding: 0;
        }
    }
    .owl-stage-outer {
      border-left:1px solid rgba(255,255,225,.3);
      border-right:1px solid rgba(255,255,225,.3);
      @media (min-width: @screen-ms) {border-right:none;}
      .owl-item {
          &.active {
            border-right:1px solid rgba(255,255,225,.3);
          }
      }
    }
    &__item{
        //float:left;
        padding: 0;
        margin: 0;

        button{
            border: 0 none;
            margin: 0;
            padding: 0 3px;
            text-decoration: none;
            color: white;
            height: 100px;
            text-align: center;
            display: block;
            font-size: 14px;
            background-color: transparent;
            transition:all 200ms ease;
            line-height: 14px;
            width: 100%;

            svg{
                fill:white;
                display: block;
                height: 60px;
                width: 60px;
                margin:0 auto;
                transition:all 200ms ease;
                user-select:none;
            }

            @media (min-width: @screen-sm) {
                line-height: inherit;
                padding: 10px 0;
                min-width: 140px;
            }

            &:hover,&:active{
                background-color: darken(@color-primary, 10%);
                svg{
                    transform: perspective(20px) translateZ(-2px);
                }
            }
        }
    }
    &__left-side,
    &__right-side{
        height: 100px;
        fill:@color-primary;
        position: absolute;
        top: 0;
        z-index: -1;
    }
    &__left-side{
        left: -100px;
    }
    &__right-side{
        right: -100px;
    }
}

.owl-prev,
.owl-next{
    position: absolute;
    font-size: 37px;
    color: @color-white;
    width: 40px;
    height: 40px;
    line-height: .75;
    border:2px solid rgba(255,255,225,.3);
    border-radius: 100px;
    text-align: center;
    padding: 0;
    margin: 0;
    top: 30px;
    cursor: pointer;
    user-select:none;
    @media (min-width: @screen-sm) {
        width: 50px;
        height: 50px;
        line-height: 1;
        top: 25px;
    }
    &:hover{
        background-color: darken(@color-primary, 10%);
    }
    &:active{
        transform: perspective(20px) translateZ(-2px);
    }
    &.disabled{
        opacity: 0.2;
    }
}
.owl-prev{
    left: 7px;
}
.owl-next{
    right: 7px;
}

// owl classes

/**
 * Owl Carousel v2.2.1
 * Copyright 2013-2017 David Deutsch
 * Licensed under  ()
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 *  Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 *  Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease; }

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d; }

/*
 *  Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }
