.part-project{
    &-container{
        overflow-y: scroll;
        overflow-x: hidden;
        height: ~"calc(80vh - 40px)";
        width: 100%;
        -webkit-overflow-scrolling: touch;

        @media (min-width: @screen-sm) {
            height: ~"calc(70vh - 60px)";
            max-height: ~"calc(70vh - 60px)";
        }
    }
    &__headline {
        margin-bottom: 25px;
    }
    &__content {
        img {
            width: 100%;
            max-width: 100%;
            height: auto;
        }
    }
    @media(min-width:@screen-sm){

        // &-thumb-container{
        //     width: ~"calc(100% - 30px)";
        //     max-height: ~"calc(70vh - 60px)";
        //     position: absolute;
        //     z-index: -1;
        //     overflow:hidden;
        // }
        // &-content-container{
        //     padding:60px;
        //     margin-top: 35%;
        //     &-inner{
        //         position: relative;
        //         background-color: @color-white;
        //         padding:30px;
        //         border-radius: 0 40px 0 40px;
        //         &:before{
        //             content: '';
        //             height: 8px;
        //             width: 40%;
        //             background-color: @color-primary;
        //             position: absolute;
        //             top:-8px;
        //             left: 0;
        //         }
        //         &:after{
        //             content: '';
        //             clear: both;
        //             display: block;
        //         }
        //     }
        // }
    }
    @media(min-width:@screen-xl){

        // &-content-container{
        //     padding:120px;
        //     margin-top: 30%;
        // }

    }
    &-excerpt{
        font-size: 120%;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid @color-gray-light;
    }
    &-content{

    }
    &-image{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    &-header{
        margin-top: 0;
    }

}


/* Old

.part-project{
    &-container{
        overflow-y: scroll;
        overflow-x: hidden;
        height: ~"calc(70vh - 60px)";
        width: 100%;
        -webkit-overflow-scrolling: touch;
    }
    @media(min-width:@screen-sm){

        &-thumb-container{
            width: ~"calc(100% - 30px)";
            max-height: ~"calc(70vh - 60px)";
            position: absolute;
            z-index: -1;
            overflow:hidden;
        }
        &-content-container{
            padding:60px;
            margin-top: 35%;
            &-inner{
                position: relative;
                background-color: @color-white;
                padding:30px;
                border-radius: 0 40px 0 40px;
                &:before{
                    content: '';
                    height: 8px;
                    width: 40%;
                    background-color: @color-primary;
                    position: absolute;
                    top:-8px;
                    left: 0;
                }
                &:after{
                    content: '';
                    clear: both;
                    display: block;
                }
            }
        }
    }
    @media(min-width:@screen-xl){

        &-content-container{
            padding:120px;
            margin-top: 30%;
        }

    }
    &-excerpt{
        font-size: 120%;
        padding-bottom: 30px;
        margin-bottom: 30px;
        border-bottom: 1px solid @color-gray-light;
    }
    &-content{

    }
    &-image{
        width: 100%;
        height: auto;
        margin-bottom: 30px;
    }
    &-header{
        margin-top: 0;
    }

}*/