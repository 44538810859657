.timeline {
	position: relative;
	margin: 0 auto;
	overflow-y: scroll;
	overflow-x: hidden;
	height: ~"calc(80vh - 40px)";
	max-height: ~"calc(80vh - 40px)";
	width: 100%;
	-webkit-overflow-scrolling: touch;

	@media (min-width: @screen-sm) {
        height: ~"calc(70vh - 60px)";
        max-height: ~"calc(70vh - 60px)";
    }

	&__title {
		text-align: center;
		margin-bottom: 40px;
		color: @color-text;
	}

	&__inner {
		position: relative;
		&:before {
			content: '';
			position: absolute;
			width: 1px;
			height: 100%;
			background: fade(#000, 20%);
			left: 5px;
			transform: translate(-50%, 30px);

			@media (min-width: @screen-sm) {
				left: 20px;
				height: ~"calc(100% - 230px)";
			}
		}
	}

	&__block {
		.row();
		margin-bottom: 30px;
		position: relative;
		margin-right: 0!important;
		margin-left: 0!important;

		.block-dot {
			background: @color-primary;
			top: 25px;
			left: -5px;
			width: 20px;
			height: 20px;
			position: absolute;
			border-radius: 50%;
			border: 5px solid @color-white;
		}

		.block-content {
			width: auto;
			margin-left: 30px;
			padding: 20px;
			position: relative;
			background: #f8f8f8;
			text-align: left;
			box-shadow: 0px 2px 12px -4px rgba(0,0,0,0.25);

			p {
				margin: 0;
			}

			&__title {
				margin: 0;
				margin-bottom: 15px;
			}

			&__date {
				color: @color-text;
				font-style: italic;
				margin-top: 10px;
				display: block;

				@media (min-width: @screen-sm) {
					margin-top: 0;
				}

				&:first-letter {
					text-transform: uppercase;
				}
			}

			&__image {
				margin-top: 20px;
				width: 100%;
				text-align: center;
				
				img {
					width: 100%;
				}
			}

			&__text {
				position: relative;
				p {
					margin: 0;
				}
			}

			&:before {
				content: '';
				height: 14px;
				width: 14px;
				position: absolute;
				left: -7px;
				top: 28px;
				background: #f8f8f8;
				transform: rotate(45deg);
			}
		}
	}

	@media (min-width: @screen-sm) {
		&__inner {
			position: relative;
			&:before {
				left: 50%;
				height: ~"calc(100% - 30px)";
			}
		}
		&__block {
			.block-dot {
				left: 50%;
				transform: translateX(-50%);
			}
			.block-content {
				width: 47%;
				margin-left: 0;
				padding: 30px;

				&__date {
					position: absolute;
					width: 100%;
					left: 112%;
					top: 8px;
					float: left;
					display: inline-block;
					margin: .8em 0;
					text-align: left;
				}

				&__icon {
					margin-top: 30px;
				}

				&:before {
					left: auto;
					right: -7px;
				}
			}
			&:nth-child(even) {
				.block-content {
					float: right;

					&__date {
						left: auto;
						right: 112%;
						text-align: right;
					}

					&:before {
						right: auto;
						left: -7px;
					}
				}
			}
		}
	}
}