/**
 * Base: Typography
 */

h1,
h2,
h3,
h4,
h5 {
    font-family: @header-font-family;
    font-weight: 900;
    line-height: 1.1;
    margin-top: 20px;
    margin-bottom: 10px;
}

h1 {
    font-size: 30px;
    @media (min-width:@screen-sm) {font-size: 36px;}
    @media (min-width:@screen-lg) {font-size: 48px;}
}

h2 {
    font-size: 21px;
    @media (min-width:@screen-sm) {font-size: 24px;}
    @media (min-width:@screen-lg) {font-size: 30px;}
}

h3 {
    font-size: 18px;
    @media (min-width:@screen-sm) {font-size: 21px;}
    @media (min-width:@screen-lg) {font-size: 24px;}
}

p.lead {
    font-style: italic;
    font-weight: 300;
    font-size: 18px;
    line-height: 1.4;

    @media (min-width:@screen-sm) {font-size: 21px;}
    @media (min-width:@screen-lg) {font-size: 24px;}
}

p,
ul {margin-bottom: 20px;}

p~h2,
p~h3 {margin-top: 36px;}

