.faq-archive {
	position: relative;
	margin: 0 auto;
	overflow-y: scroll;
	overflow-x: hidden;
	height: ~"calc(80vh - 40px)";
	max-height: ~"calc(80vh - 40px)";
	width: 100%;
	-webkit-overflow-scrolling: touch;

	@media (min-width: @screen-sm) {
		height: ~"calc(70vh - 60px)";
		max-height: ~"calc(70vh - 60px)";
	}

	&__title {
		text-align: center;
		margin-bottom: 30px;
	}
}