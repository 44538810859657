.page {
	position: relative;
	margin: 0 auto;
	overflow-y: scroll;
	overflow-x: hidden;
	height: ~"calc(80vh - 40px)";
	max-height: ~"calc(80vh - 40px)";
	width: 100%;
	-webkit-overflow-scrolling: touch;
	@media (min-width: @screen-sm) {
		height: ~"calc(70vh - 60px)";
		max-height: ~"calc(70vh - 60px)";
	}
	&__title {
		text-align: center;
		margin-bottom: 30px;

		@media (min-width: @screen-sm) {
			margin-bottom: 40px;
		}
	}
	&.bannerImage {
		.page__image {
			width: 100%;
			height: 150px;
			background-position: center center;
			background-repeat: no-repeat;
			background-size: cover;

			@media (min-width: @screen-sm) {
				height: 350px;
			}
		}
		.page__content {
			position: relative;
			z-index: 1;
			background: @color-white;
			padding: 15px;
			width: ~"calc(100% - 20px)";
			margin: -50px auto 0 auto;
			
			@media (min-width: @screen-sm) {
				width: ~"calc(100% - 60px)";
				padding: 30px;
				margin: -100px auto 0 auto;
			}

			p {
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
}