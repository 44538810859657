/**
 * Base: Grid
 */

// Set the container width, and override it for fixed navbars in media queries.
.container {
    .container-fixed();

    @media (min-width: @screen-sm-min) {width: @container-sm;}
    @media (min-width: @screen-md-min) {width: @container-md;}
    @media (min-width: @screen-lg-min) {width: @container-lg;}
    @media (min-width: @screen-xl-min) {width: @container-xl;}
}

// Rows contain and clear the floats of your columns.
.row {.make-row();}


// Columns
// Common styles for small and large grid columns
.make-grid-columns();

// Extra small grid
.make-grid(xs);

@media (min-width: @screen-ms-min) {.make-grid(ms);} // Medium small grid
@media (min-width: @screen-sm-min) {.make-grid(sm);} // Small grid
@media (min-width: @screen-md-min) {.make-grid(md);} // Medium grid
@media (min-width: @screen-lg-min) {.make-grid(lg);} // Large grid
@media (min-width: @screen-xl-min) {.make-grid(xl);} // Large grid