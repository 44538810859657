.navigation-options{
    position: fixed;
    bottom: 110px;
    right: 10px;

    @media (min-width: @screen-sm) {
        right: 70px;
        bottom: 70px;
    }

    &__trigger{
        @media(min-width:@screen-lg){
            display: none;
        }
        @media(min-width:@screen-sm) {
            width: 100px;
            height: 100px;
            padding: 0;
            border: 0 none;
        }
        border:1px solid rgba(255,255,225,.3);
        width: 60px;
        height: 60px;
        background-color: @color-primary;
        position: absolute;
        bottom: 0;
        right: 0;
        border-radius: 100px;
        padding: 4px 2px 5px 2px;
        margin: 0;
        color:@color-white;
        svg{
            fill:@color-white;
            width: 50px;
            height: 50px;
            @media(min-width:@screen-sm) {
                width: 70px;
                height: 70px;
            }
        }
        z-index: 1;

        &.active{
            opacity: .5;

            @media(min-width:@screen-sm){
                opacity: .2;
            }
        }
    }
    &__list{
        width: 100px;
        height: 100px;
        margin: 0;
        padding: 0;
        position: absolute;
        bottom: 0;
        right: 0;
        list-style-type: none;
        border-radius: 100px;
        transition:all 200ms ease;

        &.active{
            height: 460px;
            .navigation-options__item {
                width: 80px;
                height: 80px;

                button {
                    width: 80px;
                    height: 80px;
                }
            }
            li#options-answers button{
                bottom:340px;
            }
            li#options-timeline button{
                bottom:250px;
            }
            li#options-about button{
                bottom:160px;
            }
            li#options-currently button{
                bottom:70px;
            }
        }
        @media(min-width:@screen-sm){
            background-color: @color-primary;
            &.active{
                height: 460px;
                li#options-answers button{
                    bottom:370px;
                }
                li#options-timeline button{
                    bottom:280px;
                }
                li#options-about button{
                    bottom:190px;
                }
                li#options-currently button{
                    bottom:100px;
                }
            }
        }
        @media(min-width:@screen-lg){
            height: 370px;
            li#options-answers button{
                bottom:280px;
            }
            li#options-timeline button{
                bottom:190px;
            }
            li#options-about button{
                bottom:100px;
            }
            li#options-currently button{
                bottom:10px;
            }
        }
    }

    &__item{
        margin:0;padding: 0;
        width: 60px;
        height: 60px;
        line-height: 100px;
        transition: height .2s, width .2s;

        @media (min-width: @screen-sm) {
            width: 100px;
            height: 100px;
        }
        button{
            svg{
                fill:white;
                width: 50px;
                height: 50px;
            }
            text-align: center;
            text-decoration: none;
            color: white;
            font-size: .75em;
            line-height: 16px;
            font-weight: bold;
            width: 60px;
            height: 60px;
            border-radius: 100%;
            background-color: transparent;
            display: block;
            padding:6px;
            transition:all 200ms ease;
            position: absolute;
            bottom: 0;
            right: 0;
            border:1px solid rgba(255,255,225,.3);
            background-color: @color-primary;

            @media (min-width: @screen-sm) {
                background: transparent;
                right: 10px;
                bottom: 10px;
                width: 80px;
                height: 80px;
            }

            &:hover{
                background-color: darken(@color-primary, 10%);
            }
            &:active{
               transform:perspective(500px) translateZ(-100px);
            }
        }
    }
}


// animation

@keyframes listAnimate{
  0%{
    height: 70px;
  }
  50%{
    height: 280px;
  }
  80%{
    height: 240px;
  }
  100%{
    height: 260px;
  }
}