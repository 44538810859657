/**
 * Base: icons
 */

.icon {
    height: 24px;
    width: 24px;
    display: inline-block;
    fill: currentColor;
}
