/**
 * View: Frontpage
 */

button{
    transition:all 200ms ease;

    &:active, &:focus{
        outline: none;
    }
    &:active{
       transform:perspective(500px) translateZ(-100px);
    }
}


#app{
    width: 100vw;
    height: 100vh;
    border:15px solid white;
    overflow: hidden;
    opacity: 0;
    transition: opacity .5s;

    @media (min-width: @screen-sm) {
        border:30px solid white;
    }

    &.fullyLoaded {
        opacity: 1;
    }
}

#logo{
    width: 110px;
    height: 105px;
    border: 0 none;
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    background-color: @color-white;
    border-radius: 10px;
    padding: 0;

    img {
        width: 100%;
        height: auto;
    }

    @media (min-width: @screen-sm) {
        width: 150px;
        height: 150px;
        top: 20px;
        left: 60px;
        transform: translateX(0);
        position: fixed;
    }
}


// icons list

#icons-list{
    list-style-type: none;
    padding: 0;margin: 0;
    li{
        width: 20%;
        float: left;
        text-align: center;
        padding:10px;
        border:1px solid rgba(0,0,0,.2);
        svg{
            width: 48px;
            height: 48px;
        }
        span{
            display: block;
            font-size: 80%;
        }
    }
}