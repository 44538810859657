/**
 * Base: Buttons
 */

// Default button
.button {
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    background-color: @color-primary;
    white-space: nowrap;
    user-select: none;
    position: relative;
    font-weight: 400;
    font-family: @base-font-family;
    transition: background .3s, color .3s, border-color .3s;
    padding: 10px 20px;
    color: @color-white;
    border: 1px solid transparent;
    text-decoration: none;

    &:hover,
    &:focus {
        color: @color-white;
        background: darken(@color-primary, 10%);
        text-decoration: none;
        outline: none;
    }

    &[disabled],
    [disabled] & { cursor: not-allowed; }

    // Ghost button
    &--ghost {
        color: @color-text;
        border: solid 1px @color-primary;
        background-color: transparent;

        &:hover,
        &:focus {
            color: @color-white;
            background-color: @color-primary;
        }
    }
}