.category{
    &-container{
        overflow-y: scroll;
        overflow-x: hidden;
        height: ~"calc(80vh - 40px)";
        width: 100%;
        max-height: ~"calc(80vh - 40px)";
        -webkit-overflow-scrolling: touch;

        @media (min-width: @screen-sm) {
            height: ~"calc(70vh - 60px)";
            max-height: ~"calc(70vh - 60px)";
        }
    }
    &__headline {
        margin-bottom: 25px;
    }
}