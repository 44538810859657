@faq-box-color : lighten(@color-primary, 50%);

.part-project-faqs .faq{
    // @media(min-width:@screen-md){
    //     margin-top: -60px;
    //     box-shadow: 0 0 70px rgba(0, 0, 0, .2);
    // }
}
.faq{
    padding:10px 20px;
    background-color: @faq-box-color;
    position: relative;
    border-radius: 10px;
    margin-bottom: 10px;

    &-list{
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &-list__item{
        position: relative;
        padding: 0;
        margin: 0;
        border-bottom: 2px solid @color-white;
        &:last-child{
            border-bottom: 0 none;
        }

        &-checker{
            position: absolute;
            margin: 0 !important;
            padding: 0 !important;
            cursor: pointer;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            top: 0;
            left: 0;

            &:checked{
                &~div{
                    margin-top: 0;
                    max-height: 0;
                    opacity: 0;
                    padding-bottom: 0px;
                }
                &~span{
                    transform: rotate(90deg);
                }
            }
        }
        &-chevron{
            position: absolute;
            right: 0;
            top: 0px;
            display: block;
            width: 40px;
            height: 40px;
            transform: rotate(-90deg);
            text-align: center;
            line-height: 1;
            user-select:none;
            transition:all 300ms ease;
            font-size: 150%;
            @media(min-width:@screen-md){
                padding-top:3px;
            }
        }
        &-header{
            font-size: @base-font-size;
            display: block;
            background-color: @faq-box-color;
            margin: 0;
            cursor: pointer;
            padding: 8px 30px 8px 0;
            font-weight: normal;
            line-height: 1.7em;
        }
        &-content{
            font-size: 80%;
            position: relative;
            overflow: hidden;
            margin-top: 10px;
            z-index: 2;
            padding-bottom: 20px;

            p{
                margin: 0 0 10px 0;
            }
       }
    }
}