.modal{
   display: none;
    max-width: ~"calc(100vw - 30px)";
    width: 100%;
    height: 80vh;
    background-color:white;
    left: 50%;
    top: 50%;
    position: fixed;
    transform:translate(-50%, -50%);
    border-radius: 10px;
    z-index: 8001;
    padding: 0!important;
    transition: width .4s;

    @media (min-width: @screen-sm) {
        width: 80vw;
        max-width: 1300px;
        height: 70vh;
        transform:translate(-50%, -55%);

        &--no_faq {
            max-width: 900px;   
        }
    }



    &__close-btn{
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border:0 none;
        padding: 0;
        margin: 0;
        background-color: @color-secondary;
        color:white;
        text-align: center;
        line-height: 40px;
        font-size: 35px;
        position: absolute;
        top: -20px;
        right: -10px;
        
        @media (min-width: @screen-sm) {
            width: 50px;
            height: 50px;
            top: -25px;
            right: -25px;
            line-height: 50px;
            font-size: 45px;
        }
    }
    &.active{
        display: block;
    }
    &-content{
        overflow: hidden;
        padding:20px;

        @media (min-width: @screen-sm) {
            padding: 30px;
        }
    }

    &-loading{
        text-align: center;
        width: ~"calc(100% - 40px)";
        height: ~"calc(80vh - 39px)";
        position: absolute;
        background: white;
        top: 19px;
        left: 20px;
        z-index: 10;
        img{
            position: absolute;
            transform: translate(-50%,-50%);
            top: 50%;
            left: 50%;
        }
        @media (min-width: @screen-sm) {
            top: 20px;
            height: ~"calc(70vh - 40px)";
            border-radius: 20px;
        }
    }
}

#backdrop{
    display: none;
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0);
    z-index: 8000;
    top:0;
    left:0;
    transition:all 500ms ease;
    &.active{
        display: block;
        background-color: rgba(0,0,0,.7);
    }
}