.map{
    width: 100%;
    height: ~"calc(100% - 100px)";

    @media (min-width: @screen-sm) {
        height: 100%;
    }
}

.custommarker{
    width: 60px;
    height: 60px;
    transform:translate(-50%,-50%);

    // magic
    animation-name: pulse_animation;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}
.custommarker-trigger{
    background-color: @color-primary;
    width: 60px;
    height: 60px;
    padding:5px;
    border-radius: 100px;
    text-align: center;
    color: @color-white;
    line-height: 1;
    border: 0 none;
    svg{
        width: 36px;
        height: 36px;
        fill:@color-white;
    }
}

@keyframes pulse_animation {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

.loading-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    &__logo {
        width: 225px;
        height: 225px;
        display: block;
    }

    &__spinner {
        width: 60px;
        height: 60px;
        display: block;
        margin: 0 auto;
        margin-top: 30px;
    }

    &.loaded {
        opacity: 0;
        transform: translate(-99999px, -99999px);
        transition: opacity .5s, transform 0s .5s;
    }
}